<template>
  <div class="wh-100">
    <v-img
      :width="mobileLogoWidth"
      src="/img/logo/logo-black.png"
      class="brand-logo d-none d-lg-block"
    ></v-img>
    <v-row no-gutters class="wh-100">
      <v-col lg="8" class="d-none pa-10 d-lg-flex justify-center align-center">
        <v-img max-width="70%" src="/img/vector/login-vector.png"></v-img>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        class="white pa-5 d-flex justify-center align-center elevation-4"
      >
        <div>
          <div class="d-flex justify-center d-lg-none">
            <v-img
              :width="mobileLogoWidth"
              src="/img/logo/logo-black.png"
            ></v-img>
          </div>
          <v-card-title class="font-weight-light text-center px-0 mb-12">{{
            $t('title.projectName')
          }}</v-card-title>
          <v-form ref="signInForm">
            <template v-for="(items, index) in formFields">
              <div :key="index">
                <label class="label--text">{{
                  $t(`text.${items.label}`)
                }}</label>
                <AppFormField
                  v-model="items.model"
                  :type="items.type"
                  :allowShowPassword="true"
                  :outlined="true"
                  :dense="true"
                  :rules="items.rules"
                  class="mt-2"
                ></AppFormField>
              </div>
            </template>

            <v-checkbox
              v-model="rememberMe"
              :label="$t('text.rememberMe')"
              class="mt-0"
            ></v-checkbox>

            <v-btn
              block
              depressed
              color="primary"
              class="surface--text"
              @click="signIn"
              >{{ $t('text.signIn') }}</v-btn
            >
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FORM_FIELD_TYPE, ROUTE_NAME, COOKIE } from '@/constants'
import { formValidationHelper, uiHelper } from '@/utils'
import {
  AUTH_LOGIN,
  AUTH_INITIAL_LOGIN_STATE,
  AUTH_IS_AUTHENTICATED
} from '@/store/auth.module'

export default {
  name: 'Login',
  computed: {
    ...mapGetters({
      isAuthenticated: [AUTH_IS_AUTHENTICATED]
    }),
    loginComplete() {
      return this.$store.state.auth.login.complete
    },
    mobileLogoWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 100
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return 300
      } else if (this.$vuetify.breakpoint.lgAndDown) {
        return 250
      } else {
        return 400
      }
    }
  },
  watch: {
    loginComplete() {
      let response = this.$store.state.auth.login
      if (response.complete) {
        this.loginCompleteAction(response)
      }
    }
  },
  data: () => ({
    formFields: [
      {
        key: 'username',
        label: 'username',
        model: '',
        rules: formValidationHelper.usernameRules()
      },
      {
        key: 'password',
        label: 'password',
        model: '',
        rules: formValidationHelper.passwordRules(),
        type: FORM_FIELD_TYPE.PASSWORD
      }
    ],
    rememberMe: false,
    loginCompleteMessage: ''
  }),
  created() {
    this.initLogin()
  },
  methods: {
    loginCompleteAction(response) {
      if (response.code == 0 && this.isAuthenticated) {
        this.setRememberUsername()
        this.loginCompleteMessage = response.message
        this.$router.push({ name: ROUTE_NAME.HOME })
      } else {
        this.openRootSnackBar('error', response.message, 2000)
      }
      this.initialLoginState()
    },
    getUsername() {
      let username = uiHelper.getCookie(COOKIE.REMEMBER_USERNAME)

      if (username) {
        return uiHelper.decodedString(username).substring(49)
      }

      return ''
    },
    signIn() {
      let data = {
        username: this.formFields.find((x) => x.key == 'username').model,
        password: this.formFields.find((x) => x.key == 'password').model
      }

      if (this.$refs.signInForm.validate()) {
        this.login(data)
      }
    },
    setRememberUsername() {
      let username = uiHelper.encodedString(
        new Date() + this.formFields.find((x) => x.key == 'username').model
      )

      this.rememberMe
        ? uiHelper.setCookie(COOKIE.REMEMBER_USERNAME, username)
        : uiHelper.removeCookie(COOKIE.REMEMBER_USERNAME)
    },
    login(data) {
      this.$store.dispatch(AUTH_LOGIN, { data })
    },
    initialLoginState() {
      this.$store.dispatch(AUTH_INITIAL_LOGIN_STATE)
    },
    openRootSnackBar(color, message, timeout) {
      this.$root.$children[0].openSnackBar(color, message, timeout)
    },
    initLogin() {
      let username = this.getUsername()

      this.formFields.find((x) => x.key == 'username').model = username

      this.rememberMe = Boolean(username)
    }
  }
}
</script>

<style lang="scss">
.brand-logo {
  left: 2rem;
  position: absolute;
  top: 2rem;
  z-index: 1;
}
</style>
